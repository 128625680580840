<template>
  <v-card
    flat
    tile
    class="black--text text-center"
    style="width:100%; padding:0;background:transparent;"
  >
    <v-card-text class="black--text" style="padding:10px 0;">
      {{ new Date().getFullYear() }} —
      <strong>Sanqua Multi International</strong>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ['footer']
}
</script>

<style lang="scss" src=""></style>
